import { forkJoin, Observable, map } from 'rxjs'
import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route, NavigationExtras } from '@angular/router'
import { AccountService } from '@awork/_shared/services/account-service/account.service'
import { WorkspaceService } from '@awork/features/workspace/services/workspace-service/workspace.service'
import { UserService } from '@awork/features/user/services/user-service/user.service'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { apiProtocol, clientSecret } from '@awork/environments/environment'
import { decrypt } from '@awork/_shared/functions/encrypt'
import { queryStringToObject } from '@awork/_shared/functions/query-params-helper'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { Account } from '@awork/_shared/models/account.model'
import { AppStore } from '@awork/core/state/app.store'
import { AppQuery } from '@awork/core/state/app.query'
import { authentication, initialize } from '@microsoft/teams-js'
import getHostname from '@awork/_shared/functions/get-hostname'
import { SignalStoreService } from '@awork/core/state/signal-store/signalStore.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  refreshToken: string
  accessToken: string
  subdomain: string
  email: string
  expiresIn: number
  redirect: URL
  currentLoginRoute = '/login'

  // Email-related urls
  emailUrls = ['reset-password', 'accept-invitation']

  currentUrl: string
  private queryParams: Object

  private isTeams: boolean

  constructor(
    private router: Router,
    private appQuery: AppQuery,
    private appStore: AppStore,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private workspaceQuery: WorkspaceQuery,
    private browserService: BrowserService,
    private signalStoreService: SignalStoreService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.checkSignupDomain(state.url)

    return this.signalStoreService.selectOnStorageInit().pipe(
      map(() => {
        const url = state.url
        this.currentUrl = url
        this.queryParams = route.queryParams

        // Determinate if the route comes from AuthComponent
        const fromAuth = route.parent?.data?.module === 'auth'

        if (route.queryParams['ms-teams'] || this.appQuery.getIsInMSTeams()) {
          this.currentLoginRoute = '/msteamslogin'
          this.isTeams = true
        }

        if (route.queryParams['d']) {
          // Decrypt object with email, token expiration date and redirection url
          const decryptedParams = JSON.parse(decrypt(clientSecret, decodeURIComponent(route.queryParams['d'])))
          this.email = decryptedParams.email
          this.expiresIn = decryptedParams.expiresIn

          try {
            this.redirect = new URL(decryptedParams.redirect, `${apiProtocol}${location.host}`)
          } catch (_) {}

          if (
            route.queryParams['access_token'] &&
            route.queryParams['subdomain'] &&
            route.queryParams['refresh_token']
          ) {
            this.refreshToken = route.queryParams['refresh_token']
            this.accessToken = route.queryParams['access_token']
            this.subdomain = route.queryParams['subdomain']
          }
          return this.checkLogin(url, true, true)
        }

        return this.checkLogin(url, fromAuth)
      })
    )
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state)
  }

  canLoad(route: Route): Observable<boolean> {
    return this.signalStoreService.selectOnStorageInit().pipe(
      map(() => {
        const url = `/${route.path}`
        return this.checkLogin(url)
      })
    )
  }

  /**
   * Checks if the user is logged in and redirect if needed
   * @param {string} url - Referrer url, where the user was
   * @param {boolean} fromAuth - If is from the Auth module or not
   * @param {boolean} switchWorkspace - If it comes from a redirection to switch workspaces
   * @returns {boolean} - If can navigate
   */
  checkLogin(url: string, fromAuth = false, switchWorkspace = false): boolean {
    const isLoggedIn = this.appQuery.getIsLoggedIn() || switchWorkspace
    if (isLoggedIn) {
      if (!fromAuth || this.currentUrl.includes('verify-email')) {
        // If is logged in and is not from Auth module
        return true
      }

      if (fromAuth) {
        // If is logged in and is from Auth module
        if (this.workspaceQuery.getActive() || switchWorkspace) {
          if (switchWorkspace) {
            this.setLocalStorage()
          } else {
            // Navigate to the dashboard page if already logged in
            this.goToFramework()
          }

          return false
        } else {
          return true
        }
      }
    } else if (!isLoggedIn && fromAuth) {
      // If is not logged in and is from Auth module

      // Exclude the subdomain cookie check from email-related urls
      if (!this.emailUrls.some(emailUrl => this.currentUrl.includes(emailUrl))) {
        // If the subdomain cookie is set, redirect to the workspace's subdomain
        this.accountService.checkSubdomainCookie(true, undefined, this.queryParams)
      }

      // If the user is not logged in and in a subdomain, remove the subdomain cookie
      if (this.browserService.isCustomSubdomain()) {
        this.accountService.removeSubdomainCookie()
      }

      return true
    }

    let extras: NavigationExtras

    // If true, then the user just logged in or switch workspaces
    if (this.expiresIn && this.email) {
      extras = {
        queryParams: {
          loading: true,
          ...queryStringToObject(location.search)
        },
        queryParamsHandling: 'merge'
      }
    }

    // Navigate to the login page if not logged in and not from Auth module
    this.router.navigate(
      [this.currentLoginRoute],
      extras ? extras : { queryParams: queryStringToObject(location.search) }
    )
    return false
  }

  /**
   * Set all the localStorage with the new cookie
   */
  setLocalStorage(): void {
    const subdomain = this.browserService.getSubdomain()
    const account = new Account(this.email, this.expiresIn)

    this.accountService.updateAccount({
      email: this.email,
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      refreshAt: account.refreshAt
    })

    // Sets user and workspace
    forkJoin([
      this.userService.fetchUser(),
      // If the subdomain is set in the URL, use it, otherwise use the one passed in the query string.
      this.workspaceService.getWorkspace(subdomain !== null ? subdomain : this.subdomain),
      this.workspaceService.getWorkspacesByEmail(this.email, false)
    ]).subscribe(
      res => {
        this.appStore.update({ isLoggedIn: true })
        this.goToFramework()
        return false
      },
      err => {
        this.appStore.update({ isLoggedIn: false })
        this.router.navigate([this.currentLoginRoute])
        // TODO: error response for the user missing
      }
    )
  }

  /**
   * Redirects to inside the framework
   */
  private goToFramework(): void {
    let redirectUrl = this.accountService.appsFlyerRedirect(location.href)
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    } else {
      redirectUrl =
        this.redirect && this.redirect.pathname && !this.redirect.pathname.includes('undefined')
          ? this.redirect.pathname
          : '/my/dashboard'
    }

    // In case we are logged in and in ms teams, tell the frame
    // that the auth was successful.
    // This will close the login popup, otherwise you see awork in a
    // popup instead of a ms teams tab.
    if (redirectUrl === '/my/dashboard' && this.isTeams) {
      try {
        initialize()
        forkJoin([
          this.signalStoreService.selectOnStorePersisted('account'),
          this.signalStoreService.selectOnStorePersisted('app')
        ]).subscribe(() => authentication.notifySuccess(''))
      } catch (err) {}
    }

    const params = queryStringToObject(this.redirect ? this.redirect.search : location.search)
    this.router.navigate([redirectUrl], { queryParams: params })
  }

  /**
   * If the user goes to the sign-up page in a custom subdomain, redirects to the global sign-up page
   * @param {string} url
   */
  private checkSignupDomain(url: string): void {
    if (url === '/signup' && this.browserService.isCustomSubdomain()) {
      const hostname = getHostname()
      window.location.replace(`https://app.${hostname}/signup`)
    }
  }
}
