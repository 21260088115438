import { HttpErrorResponse } from '@angular/common/http'
import { camelCase } from '@awork/_shared/functions/lodash'

interface IErrorMessage {
  code: string
  title: string
  description: string
}

export class ErrorMessage {
  code: string
  title: string
  description: string

  constructor(errorMessage: IErrorMessage) {
    Object.assign(this, errorMessage)
  }

  /**
   * Gets the error message from the translations (or a generic error message if not found)
   * @param {HttpErrorResponse} error
   * @return {ErrorMessage}
   * TODO: Use the detail of the error (if available) to show a more detailed error
   */
  static getErrorMessage(error: HttpErrorResponse): ErrorMessage {
    // Transform the error code to camel case to look for compatible translation key
    const code = error.error ? camelCase(error.error.code) : null
    if (code in q.translations.errors) {
      const translation = q.translations.errors[code]
      const title = translation.title
      const description = translation.description
      return new ErrorMessage({ code, title, description })
    } else {
      // Fallback to "Something went wrong" if we don't have translations
      // for the error
      const title = null
      const description = q.translations.errors.somethingWentWrong
      return new ErrorMessage({ code, title, description })
    }
  }
}
